import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import ResourcesCard from "components/ResourcesCard";

const ResourcesTitle = styled("h1")`
    margin-bottom: 1em;
`

const ResourcesGrid = styled("div")`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5em;

    @media(max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        grid-gap: 2.5em;
    }
`

const Resources = ({ resources, meta }) => (
    <>
        <Helmet
            title={`Resources | Carolyn Stransky`}
            htmlAttributes={{ lang: "en" }}
            link={[
                {
                    rel: `canonical`,
                    href: `https://workwithcarolyn.com/resources`
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: `Talk resources and slide from presentations by Carolyn Stransky.`,
                },
                {
                    property: `og:title`,
                    content: `Resources | Carolyn Stransky`,
                },
                {
                    property: `og:description`,
                    content: `Talk resources and slide from presentations by Carolyn Stransky.`,
                },
                {
                    name: `og:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: `Resources | Carolyn Stransky`,
                },
                {
                    name: `twitter:description`,
                    content: `Talk resources and slide from presentations by Carolyn Stransky.`,
                },
                {
                    name: `twitter:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                }
            ].concat(meta)}
        />
        <Layout>
            <ResourcesTitle>
                Resources
            </ResourcesTitle>
            <ResourcesGrid>
                {resources.map((resources, i) => (
                    <ResourcesCard
                        key={i}
                        title={resources.node.resources_title}
                        description={resources.node.resources_preview_description}
                        uid={resources.node._meta.uid}
                    />
                ))}
            </ResourcesGrid>
        </Layout>
    </>
);

export default ({ data }) => {
    const resources = data.prismic.allResourcess.edges;
    const meta = data.site.siteMetadata;
    if (!resources) return null;

    return (
        <Resources resources={resources} meta={meta}/>
    )
}

Resources.propTypes = {
    resources: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};


export const query = graphql`
    {
        prismic {
            allResourcess(sortBy: resources_date_DESC) {
                edges {
                    node {
                        resources_title
                        resources_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`

